<template>
  <div id="app">
    <div class="home-container" :style="{
    background: `url('${dailyBackgroundImage}') center center no-repeat #666`
  }">
      <div class="home-container-body">
        <Logo/>
        <Operation/>
      </div>
      <div class="home-container-body-overlay"></div>
      <a class="beian" href="https://beian.miit.gov.cn/" target="_blank" rel="nofollow">鄂ICP备18018010号-1</a>
    </div>
  </div>
</template>

<script>

import Logo from "components/Logo";
import Operation from "./components/Operation";

export default {
  name: 'App',
  data() {
    return {
      dailyBackgroundImage: '',
      showOne: false,
      showTwo: false
    }
  },
  components: {
    Operation,
    Logo
  },
  created() {
    this.initDailyBackground()
  },
  methods: {
    initDailyBackground() {
      this.$axios({
        url: 'https://api.asilu.com/bg/',
        adapter: this.$jsonpAdapter,
      }).then(res => {
        this.dailyBackgroundImage = res.data.images[0].url
      })
    }
  }
}
</script>

<style lang="less" scoped>
.home-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-body {
    height: 100%;
    padding: 0 60px;
    color: #ccc;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &-body-overlay {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    z-index: 1;
    opacity: .55;
  }

  .beian {
    color: #ccc;
    z-index: 999;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: 1px;
  }
}
</style>
