<template>
  <div class="operation-container" ref="operationContainer">
    <div class="operation-text">
      <p>小程序《恰了么》 获取每日红包</p>
    </div>
    <div class="operation-button">
      <p class="operation-button-item" @click="handleJump('https://shop.iamdanta.cn')">购物查券</p>
      <p class="operation-button-item" @click="handleJump('http://dpurl.cn/Vb72WGzz')">美团红包</p>
      <p class="operation-button-item" @click="handleJump('https://s.click.ele.me/JtEghcu')">饿了么券</p>
      <p class="operation-button-item" @click="handleJump('https://v.didi.cn/vOOX7J8?source_id=12469jutuikeiamdanta')">滴滴8折</p>
      <p class="operation-button-item" @click="handleJump('https://jq.qq.com/?_wv=1027&k=BGCMkb7H')">福利Q群</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Operation",
  mounted() {
    let dom = this.$refs.operationContainer.childNodes
    dom.forEach((item, index) => {
      item.className += ' no-animation'
      setTimeout(() => {
        item.className += ' animation'
      }, 1200 + index * 200)
    })
  },
  methods: {
    handleJump(url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped lang="less">
.operation-container {
  margin-top: 30px;

  .operation-text {
    text-align: center;
    margin: 10px 0 30px;
  }

  .operation-button {
    display: flex;
    flex-wrap: wrap;

    &-item {
      min-width: 105px;
      height: 38px;
      box-sizing: border-box;
      cursor: pointer;
      color: #fff;
      opacity: 0.8;
      padding: 8px 20px;
      border: 1px solid #fff;
      border-radius: 20px;
      font-size: 0.9em;
      font-weight: bold;
      letter-spacing: 1px;
      text-align: center;
      margin: 5px 5px 0 5px;
    }
  }
}
</style>
