<template>
  <div class="logo-container" ref="logoContainer">
    <div class="logo-image">
      <img src="../assets/danta.png" alt="">
    </div>
    <h1 class="logo-text">DanTa</h1>
    <p class="logo-subtext">For you Thousands Times</p>
    <hr>
    <P class="daily-text">{{ dailyText }}</P>
    <p class="daily-text-author">-「{{ dailyTextAuthor }}」</p>
  </div>
</template>

<script>
export default {
  name: "Logo",
  data() {
    return {
      dailyText: '如何得与凉风约，不共尘沙一并来!',
      dailyTextAuthor: '中牟道中'
    }
  },
  created() {
    this.initDailyText()
  },
  mounted() {
    let dom = this.$refs.logoContainer.childNodes
    dom.forEach((item, index) => {
      item.className += ' no-animation'
      setTimeout(() => {
        item.className += ' animation'
      }, index * 200)
    })
  },
  methods: {
    initDailyText() {
      this.$axios.get('https://v1.hitokoto.cn').then(res => {
        this.dailyText = res.data.hitokoto
        this.dailyTextAuthor = res.data.from
      })
    }
  }
}
</script>

<style scoped lang="less">
.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo-image {
    margin: 0 auto;

    img {
      width: 120px;
      height: 120px;
      border-radius: 50%;
    }
  }

  .logo-text {
    color: #fff;
    font-size: 2.5em;
    font-family: 'Old English Text MT', "Engravers' Old English BT", 'Lato', "PingFang SC", "Microsoft YaHei", sans-serif;
    margin-bottom: 10px;
  }

  .logo-subtext {
    font-weight: lighter;
    letter-spacing: 3px;
    color: #ccc;
    font-size: 1.0em;
    font-family: "ff-tisa-web-pro-1", "ff-tisa-web-pro-2", "Lucida Grande", "Hiragino Sans GB", "Hiragino Sans GB W3", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
    margin-bottom: 10px;
  }

  hr {
    width: 100%;
    margin: 20px auto;
    border: none;
    border-top: 1px solid rgba(255, 255, 255, 0.14);
  }

  .daily-text {
    margin-bottom: 10px;
  }

  .daily-text-author {
    font-weight: bold;
  }
}
</style>
